<template>
  <div class="test_group_warp">
    <div class="left">
      <LeftController @initList="initList" />
    </div>
    <div class="right">
      <TopCard ref="TopCardRef"
               text="组卷测试"
               :showBack="false" />
      <div class="list">
        <div class="warp">
          <el-row v-for="(row,index) in tableData"
                  :key="index">
            <el-col :span="5">
              <div class="subject">
                科目:{{row.subject_name}}
              </div>
            </el-col>
            <el-col :span="14">
              <div class="telname">
                {{row.template_name}}
              </div>
            </el-col>
            <el-col :span="5">
              <div class="btn_wrap">
                <!-- <div class="btn">
                  打印试卷
                </div> -->
                <div class="btn"
                     @click="doPaper(row)">
                  组卷做卷
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LeftController from '@/views/components/left_controller.vue'
import TopCard from '@/views/components/top_card/index.vue'
import { getStudentSubject, doPaper } from '@/api/paper_template.js'
export default {
  data () {
    return {
      tableData: []
    }
  },
  components: {
    LeftController, TopCard
  },
  mounted () {
    this.$removePaperInfo()
  },
  computed: {

  },
  methods: {
    async initList (subject_id) {
      let params = {
        subject_id
      }
      const { data } = await getStudentSubject(params)
      this.tableData = data.list
    },
    async doPaper (row) {
      let params = {
        template_id: row.template_id,
        app_id: 3,
        type: 1
      }
      this.$router.push(`/doPaper?type=1&form=${JSON.stringify(params)}`)
    }
  }
}
</script>

<style lang="scss" scoped>
.test_group_warp {
  overflow: hidden;
  display: flex;
  .left {
    width: 100rem;
  }
  .right {
    width: calc(100% - 100rem);
    background: #f6f6f6;
    .tabs {
      margin-top: 22rem;
      display: flex;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 18rem;
      .tab {
        cursor: pointer;
        width: 260rem;
        height: 54rem;
        background: #2196f3;
        border-radius: 30rem;
        color: #ffffff;
        font-size: 24rem;
        font-weight: 800;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .list {
      width: 100%;
      max-height: calc(100vh - 60rem - 40rem);
      background: white;
      overflow-y: auto;
      -ms-overflow-style: none;
      overflow: -moz-scrollbars-none;
      &::-webkit-scrollbar {
        width: 0 !important;
      }
      .el-row {
        height: 80rem;
        border-bottom: 1rem solid #f1f1f1;
        &:hover {
          background: rgba($color: #2196f3, $alpha: 0.1);
        }
      }
      div {
        height: 80rem;
        line-height: 80rem;
      }
      .subject {
        font-size: 20rem;
        font-weight: 500;
        color: #333333;
        text-indent: 50rem;
      }
      .telname {
        font-weight: bold;
        color: #000000;
        font-size: 20rem;
      }
      .btn_wrap {
        display: flex;
        justify-content: flex-end;
        .btn {
          width: 120rem;
          height: 44rem;
          background: #2196f3;
          border-radius: 5rem;
          font-size: 20rem;
          font-weight: bold;
          color: #ffffff;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 20rem;
          margin-top: 20rem;
          cursor: pointer;
          // &:nth-child(1) {
          //   background: #e6f0ff !important;
          //   color: #2196f3 !important;
          // }
        }
      }
    }
  }
}
</style>